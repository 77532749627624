import React, { FC } from "react";
import { Tag, TagProps } from "antd";

export type K2StatusTagSize = "default" | "large";

interface StatusTagProps extends TagProps {
  size?: K2StatusTagSize;
  color: string;
  icon?: React.ReactNode;
}

const K2StatusTag: FC<StatusTagProps> = ({
  size = "default",
  color,
  icon,
  children,
}) => {
  return (
    <Tag
      style={
        size === "default"
          ? undefined
          : {
              fontSize: 16,
              padding: "4px 8px",
            }
      }
      color={color}
      icon={icon}
    >
      {children}
    </Tag>
  );
};

export default K2StatusTag;
