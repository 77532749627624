/*
When editing colours in this file, please also check the ColourHelpers.getThemeColors method
in which individual charities' brand colours are merged with the default variables in this file.
You may need to add additional lines to ColourHelpers.getThemeColors if you are setting properties here
to already declared properties, as these will not be copied by default. E.g

variables.button_hover_color = variables.primary_4;

Thanks
 */
import ColourHelpers from "common/helpers/colours";

export interface ThemeObject {
  [key: string]: string;
}

const variables: ThemeObject = {};

variables.classPrefix = "n3o";

variables.fontFamily = "'Poppins', sans-serif";
variables.fontFamily_1 = "Manrope";

variables.dataEntryHeight = "40px";
variables.dataEntryInnerHeight = "38px";

// Required when setting branding for subscription
variables.primaryColor = "#2196F3";

variables.primary_1 = ColourHelpers.getPrimary1(variables.primaryColor);
variables.primary_2 = ColourHelpers.getPrimary2(variables.primaryColor);
variables.primary_3 = ColourHelpers.getPrimary3(variables.primaryColor);
variables.primary_4 = ColourHelpers.getPrimary4(variables.primaryColor);
variables.primary_5 = ColourHelpers.getPrimary5(variables.primaryColor);
variables.primary_6 = ColourHelpers.getPrimary6(variables.primaryColor);
variables.primary_7 = ColourHelpers.getPrimary7(variables.primaryColor);
variables.primary_8 = ColourHelpers.getPrimary8(variables.primaryColor);
variables.primary_9 = ColourHelpers.getPrimary9(variables.primaryColor);
variables.primary_10 = ColourHelpers.getPrimary10(variables.primaryColor);

variables.blue_2 = "#bae7ff";
variables.blue_3 = "#91d5ff";
variables.blue_5 = "#40a9ff";
variables.blue_6 = "#2196F3";
variables.blue_7 = "#1272CC";
variables.blue_8 = "#0754A6";

variables.green_6 = "#52c41a";
variables.green_7 = "#389E0D";

variables.orange_3 = "#FFD591";
variables.orange_6 = "#fa8c16";

variables.gold_3 = "#ffe58f";
variables.gold_6 = "#F5B323";

variables.white_color = "#ffffff";
variables.black_color = "#000000";
variables.text_color = "#545454";
variables.text_color_secondary = "#595959";
variables.heading_color = "#535353";
variables.header_text_color = "#262626";
variables.layout_header_background = "#fefefe";
variables.layout_header_background_dark = "#003365";
variables.layout_footer_background = "#fffffe";
variables.outer_layout_background = "#F9F9FE";
variables.body_background = "#F9F9FE";
variables.inner_layout_background = "#ffffff";
variables.nav_bar_bg = "#fffffc";
variables.nav_bar_text_color = "#545454";

variables.tallyYellow = "#fadb14";

// Grey ColorPicker Variables
variables.grey_2 = "#f7f7fa";
variables.grey_3 = "#f5f5f5";
variables.grey_4 = "#e9e9f0";
variables.grey_5 = "#d1d3e5";
variables.grey_6 = "#a8aabf";
variables.grey_7 = "#838599";
variables.grey_8 = "#494b5c";
variables.grey_9 = "#282938";
variables.grey_10 = "#E8E9F0";

// Shades of Green
variables.green_1 = "#f6ffed";
variables.green_2 = "#d9f7be";
variables.green_3 = "#b7eb8f";
variables.green_4 = "#95de64";
variables.green_5 = "#73d13d";
variables.green_6 = "#52c41a";
variables.green_7 = "#389e0d";
variables.green_8 = "#237804";
variables.green_9 = "#135200";
variables.green_10 = "#092b00";

variables.red_5 = "#FF4D4F";
variables.red_6 = "#f5222d";
variables.volcano_6 = "#FA541C";

variables.yellow_1 = "#FEFFE6";
variables.yellow_7 = "#D4B106";

variables.icon_grey_light = "#b8b9cc";
variables.icon_grey_normal = variables.text_color;

// Heading font size
variables.h1_font_size = "22px";
variables.h2_font_size = "20px";
variables.h3_font_size = "18px";
variables.h4_font_size = "16px";
variables.h5_font_size = "14px";
variables.h6_font_size = "13px";

variables.hor_nav_text_color = "#fffffd";
variables.side_nav_text_color = "#fffffd";

variables.border_radius_base = "6px";
variables.border_radius_sm = "8px";
variables.border_radius_lg = "10px";

variables.shadow_color = "rgba(61, 105, 190, 0.256)";
variables.shadow_thin = "1px 1px 7px 0px rgba(61, 105, 190, 0.256)";
variables.info_color = variables.blue_6;
variables.success_color = variables.green_6;
variables.processing_color = variables.primaryColor;
variables.error_color = variables.red_6;
variables.highlight_color = variables.red_6;
variables.warning_color = variables.orange_6;
variables.normal_color = "#d9d9d9";
variables.danger_color = variables.red_6;
variables.light_color = "#e8eaf6";
variables.dark_color = variables.grey_9;
variables.dark_3 = variables.grey_7;
variables.dark_4 = "#B8B9CC";

variables.purple_1 = "#f8f0ff";
variables.purple_2 = "#EFDBFF";
variables.purple_7 = "#531DAB";

variables.blue_color = variables.blue_6;
variables.indigo_color = "#3c1991";
variables.purple_color = "purple_6";
variables.golden_purple = "#B37FEB";
variables.cyan = "#13C2C2";
variables.pink_color = "pink_6";
variables.red_color = variables.red_6;
variables.red_1 = "#FFF1F0";
variables.orange_color = variables.orange_6;
variables.yellow_color = "yellow_6";
variables.amber_color = "yellow_color";
variables.green_color = variables.green_6;
variables.teal_color = "#20c997";
variables.cyan_color = "cyan_6";
variables.gold_color = "gold_6";
variables.lime_color = "lime_6";
variables.sepia_color = "#7c3535";

variables.font_size_base = "14px";
variables.font_size_lg = "16px";
variables.font_size_xl = "24px";
variables.font_size_sm = "11px";
variables.line_height_xxl = "32px";
variables.line_height_xl = "28px";
variables.line_height_lg = "22px";
variables.line_height_md = "20px";
variables.line_height_xs = "12px";
variables.line_height_base = "1.3";
variables.border_radius_xxl = "30px";
variables.border_radius_circle = "50%";

// Border color
variables.border_color_base = variables.grey_5; // base border outline a component
variables.border_color_split = "#e9e9f0"; // split border inside a component
// border_color_base: 'hsv(0, 0, 85%)',
// border_color_split: 'hsv(0, 0, 91%)',
variables.border_width_base = "1px"; // width of the border for a component
variables.border_style_base = "solid"; // style of a components border
variables.border_color = "border_color_split";

// LINK
variables.link_color = variables.primaryColor;
variables.link_hover_color = variables.primary_7;
variables.link_active_color = variables.primary_8;

variables.tile_hover_color = variables.primary_2;

// BUTTON
variables.button_hover_color = "#4DB5FF";

variables.headings_font_weight = "400";
variables.headings_line_height = "1.2";
variables.font_weight_light = "200";
variables.font_weight_normal = "300";
variables.font_weight_semi_bold = "400";
variables.font_weight_bold = "500";
variables.font_weight_heavy = "600";

// Card
variables.card_head_color = "#535353";
variables.card_head_background = "component_background";
variables.card_head_padding = "16px";
variables.card_inner_head_padding = "12px";
variables.card_padding_base = "24px";
variables.card_padding_wider = "24px";
variables.card_actions_background = "background_color_light";
variables.card_shadow =
  "0px 0.798012px 2.19453px rgba(16, 40, 117, 0.0282725), 0px 2.68036px 7.37098px rgba(16, 40, 117, 0.0417275), 0px 12px 33px rgba(16, 40, 117, 0.07)";
variables.n3o_card_padding_base = "24px";
variables.n3o_card_padding_sm = "n3o_card_padding_base _ 10px";
variables.n3o_card_shadow = `0 8px 16px 8px fade(${variables.shadow_color}, 30%)`;
variables.n3o_card_shadow_lg = `0 16px 40px 16px fade(${variables.shadow_color}, 50%)`; //0 0 4px 4p;
variables.n3o_card_shadow_md = `0 0 5px 5px fade(${variables.black_color}, 05%)`;
variables.n3o_card_margin_base = "32px";
variables.n3o_card_margin_base_res = "20px";

// Gutter
variables.grid_gutter_width = "32px";

//Sizes Variables
variables.size_8 = "8px";
variables.size_10 = "10px";
variables.size_20 = "20px";
variables.size_30 = "30px";
variables.size_36 = "36px";
variables.size_40 = "40px";
variables.size_50 = "50px";
variables.size_60 = "60px";
variables.size_80 = "80px";
variables.size_100 = "100px";
variables.size_120 = "120px";
variables.size_150 = "150px";
variables.size_175 = "175px";
variables.size_200 = "200px";

// Letter Spacing variables
variables.letter_spacing_base = "3px";
variables.letter_spacing_lg = "6px";
variables.letter_spacing_xl = "8px";

// Apps Variables
variables.app_sidebar_width = "230px";
variables.app_chat_sidebar_width = "315px";

// Form
variables.label_required_color = variables.red_6;
variables.label_color = "#535353";
variables.form_item_margin_bottom = "20px";
variables.form_item_trailing_colon = "true";
variables.form_vertical_label_padding = "0 0 8px";
variables.form_vertical_label_margin = "0";

// Input
// _
variables.input_placeholder_color = "#a8aabf";
variables.input_text_color = "rgba(0, 0, 0, 0.65)";
variables.input_height_base = "36px";
variables.input_height_lg = "40px";
variables.input_height_sm = "24px";
variables.input_height_base_sm = "28px";

// Buttons
variables.btn_height_base = "36px";
variables.btn_height_lg = "40px";
variables.btn_height_sm = "24px";
variables.btn_height_base_sm = "28px";
variables.btn_font_size_lg = "16px";
variables.btn_font_size_sm = "12px";
variables.btn_font_size_xs = "10px";
variables.btn_padding_xs = "0 6px";
variables.btn_border_radius_base = "8px";
variables.btn_border_radius_sm = "8px";
variables.btn_spacing_lg = "16px";
variables.btn_spacing_md = "8px";
variables.btn_spacing_sm = "8px";

// Dragndrop
variables.dragndrop_paddding_tb = "24px";
variables.dragndrop_paddding_lr = "16px";

//Pricing Table
variables.n3o_pricing_table_base = "30px";

// Customizer
variables.n3o_customizer_width = "370px";
variables.n3o_customizer_base = "20px";

//Framed Layout
variables.framed_layout_base = "20px";

//Screen Sizes
variables.screen_xs_max = "575px";
variables.screen_sm_max = "767px";
variables.screen_md_max = "991px";
variables.screen_lg_max = "1199px";
variables.screen_xl = "1200px";

variables.screen_lg_min = "920px";
variables.screen_tab = "992px";
variables.max_content_width = "1770px";

// Colours for use in charts
variables.chart_colour_1 = "#5048B5";
variables.chart_colour_2 = "#9349AC";
variables.chart_colour_3 = "#BD51A0";
variables.chart_colour_4 = "#DA6293";
variables.chart_colour_5 = "#ED7A8B";
variables.chart_colour_6 = "#F89588";
variables.chart_colour_7 = "#FDB18D";
variables.chart_colour_8 = "#FFCC9C";
variables.chart_colour_9 = "#fff0f6";
variables.chart_colour_10 = "#597ef7";

variables.chart_colour_11 = "#FFD8BF";
variables.chart_colour_12 = "#FFD8BF";
variables.chart_colour_13 = "#FFBB96";

variables.chart_colour_14 = "#FFF2E8";
variables.chart_colour_15 = "#FFBB96";
variables.chart_colour_16 = "#FF7A45";
variables.chart_colour_17 = "#D4380D";

variables.chart_color_18 = "#9254DE";
variables.chart_color_19 = "#87E8DE";
variables.chart_color_20 = "#FFC53D";
variables.chart_color_21 = "#2d75f0";
variables.chart_color_22 = "#6EA0F5";
variables.chart_color_23 = "#C4DEFF";

variables.transition_speed_default = "0.2s";

variables.avatar_background = "#87d068";

export default variables;
