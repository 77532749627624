import React, { FC, ReactText } from "react";

import Icon, {
  CreditCardOutlined,
  FileDoneOutlined,
  FileTextOutlined,
  FolderOpenOutlined,
  HeartOutlined,
  HistoryOutlined,
  InboxOutlined,
  LinkOutlined,
  LoadingOutlined,
  MailOutlined,
  PieChartOutlined,
  PrinterOutlined,
  ProfileOutlined,
  SendOutlined,
  TeamOutlined,
  UploadOutlined,
  UserOutlined,
} from "@ant-design/icons";
import { AntdIconProps } from "@ant-design/icons/es/components/AntdIcon";
import { DownloadOutlined, RetweetOutlined } from "@ant-design/icons/lib";
import _ from "lodash";

import { AppId } from "appRedux/modules/sharedTypes";
import { useAppContext } from "common/contexts/AppProvider/AppProvider";
import { ColorsType } from "common/contexts/AppProvider/types";
import { AccountData } from "components/n3oIcon/svgs/AccountData";
import { DeleteAccount } from "components/n3oIcon/svgs/DeleteAccount";
import { EngageIcon } from "components/n3oIcon/svgs/EngageIcon";
import { GivingData } from "components/n3oIcon/svgs/GivingData";
import { Mailchimp } from "components/n3oIcon/svgs/Mailchimp";
import { PaymentReconciliation } from "components/n3oIcon/svgs/PaymentReconciliation";
import { PledgeIcon } from "components/n3oIcon/svgs/PledgeIcon";
import { PledgeSearchIcon } from "components/n3oIcon/svgs/PledgeSearchIcon";
import { Post } from "components/n3oIcon/svgs/Post";
import { RefundPayment } from "components/n3oIcon/svgs/RefundPayment";
import { SearchPayments } from "components/n3oIcon/svgs/SearchPayments";
import { SponsorshipData } from "components/n3oIcon/svgs/SponsorshipData";
import { StatsDown } from "components/n3oIcon/svgs/StatsDown";
import { StatsUp } from "components/n3oIcon/svgs/StatsUp";
import { TallyIcon } from "components/n3oIcon/svgs/TallyIcon";
import { TaxRelief } from "components/n3oIcon/svgs/TaxRelief";
import { TaxReliefSubmission } from "components/n3oIcon/svgs/TaxReliefSubmission";
import { UnderConstruction } from "components/n3oIcon/svgs/UnderConstruction";
import { Csv } from "components/n3oIcon/svgs/csv";
import { Json } from "components/n3oIcon/svgs/json";

import EditLinkIcon from "../editLinkIcon";
import { AccountPreference } from "./svgs/AccountPreference";
import { AmericanExpressCard } from "./svgs/AmericanExpressCard";
import { AnalyticsDashboard } from "./svgs/AnalyticsDashboard";
import { AnalyticsGraph } from "./svgs/AnalyticsGraph";
import { AppSuite } from "./svgs/AppSuite";
import { Bookmark } from "./svgs/Bookmark";
import { CallCampaign } from "./svgs/CallCampaign";
import { CallCenterHeadPhone } from "./svgs/CallCenterHeadPhone";
import { CallHistory } from "./svgs/CallHistory";
import { CallRecord } from "./svgs/CallRecord";
import { CallRecords } from "./svgs/CallRecords";
import { Callback } from "./svgs/Callback";
import { Callcenter } from "./svgs/Callcenter";
import { Comms } from "./svgs/Comms";
import { ContactCenter } from "./svgs/ContactCenter";
import { CreateAccount } from "./svgs/CreateAccount";
import { CreateDonation } from "./svgs/CreateDonation";
import { CreateSponsorship } from "./svgs/CreateSponsorship";
import { CreateTask } from "./svgs/CreateTask";
import { CustomCorrespondenceData } from "./svgs/CustomCorrespondenceData";
import { DataExport } from "./svgs/DataExport";
import { DeleteTask } from "./svgs/DeleteTask";
import { DinersClubCard } from "./svgs/DinersClubCard";
import { DiscoverCard } from "./svgs/DiscoverCard";
import { DonorCare } from "./svgs/DonorCare";
import { DoubleArrowBoxDown } from "./svgs/DoubleArrowBoxDown";
import { DoubleArrowBoxUp } from "./svgs/DoubleArrowBoxUp";
import { DueDate } from "./svgs/DueDate";
import { EmptyChart } from "./svgs/EmptyChart";
import { EmptyTask } from "./svgs/EmptyTask";
import { EndCall } from "./svgs/EndCall";
import { EndShift } from "./svgs/EndShift";
import { ExternalLink } from "./svgs/ExternalLink";
import { FeedbackCenter } from "./svgs/FeedbackCenter";
import { FeedbackCreate } from "./svgs/FeedbackCreate";
import { FeedbackData } from "./svgs/FeedbackData";
import { FeedbackRenew } from "./svgs/FeedbackRenew";
import { FeedbackReport } from "./svgs/FeedbackReport";
import { FeedbackSearch } from "./svgs/FeedbackSearch";
import { FeedbackStopCancel } from "./svgs/FeedbackStopCancel";
import { Feedbacks } from "./svgs/Feedbacks";
import { InboundCall } from "./svgs/InboundCall";
import { JCBCard } from "./svgs/JCBCard";
import { Mailroom } from "./svgs/Mailroom";
import { MastercardCard } from "./svgs/MastercardCard";
import { MergeAccount } from "./svgs/MergeAccount";
import { OnBreak } from "./svgs/OnBreak";
import { OutboundCall } from "./svgs/OutboundCall";
import { Outbox } from "./svgs/Outbox";
import { PaymentAgencyIncome } from "./svgs/PaymentAgencyIncome";
import { PaymentBankTransfer } from "./svgs/PaymentBankTransfer";
import { PaymentCardDeactivate } from "./svgs/PaymentCardDeactivate";
import { PaymentCash } from "./svgs/PaymentCash";
import { PaymentChange } from "./svgs/PaymentChange";
import { PaymentCheque } from "./svgs/PaymentCheque";
import { PaymentCreditCard } from "./svgs/PaymentCreditCard";
import { PaymentDirectDebit } from "./svgs/PaymentDirectDebit";
import { PickUpCall } from "./svgs/PickUpCall";
import { PledgeAllocation } from "./svgs/PledgeAllocation";
import { RegularGiving } from "./svgs/RegularGiving";
import { RemindDate } from "./svgs/RemindDate";
import { ReportDashboard } from "./svgs/ReportDashboard";
import { ResumeCall } from "./svgs/ResumeCall";
import { RoundPointingArrowTopRight } from "./svgs/RoundPointingArrowTopRight";
import { SearchAccount } from "./svgs/SearchAccount";
import { SearchDonation } from "./svgs/SearchDonation";
import { SearchSponsorship } from "./svgs/SearchSponsorship";
import { SendGrid } from "./svgs/SendGrid";
import { SendStatement } from "./svgs/SendStatement";
import { Sendy } from "./svgs/Sendy";
import { Snooze } from "./svgs/Snooze";
import { Sponsorship } from "./svgs/Sponsorship";
import { SponsorshipBeneficiary } from "./svgs/SponsorshipBeneficiary";
import { SponsorshipRenew } from "./svgs/SponsorshipRenew";
import { SponsorshipReport } from "./svgs/SponsorshipReport";
import { SponsorshipStop } from "./svgs/SponsorshipStop";
import { SponsorshipTransfer } from "./svgs/SponsorshipTransfer";
import { Task } from "./svgs/Task";
import { TaskNote } from "./svgs/TaskNote";
import { UnionPayCard } from "./svgs/UnionPayCard";
import { UnknownCard } from "./svgs/UnknownCard";
import { ViewAccount } from "./svgs/ViewAccount";
import { ViewDonation } from "./svgs/ViewDonation";
import { ViewLogs } from "./svgs/ViewLogs";
import { VisaCard } from "./svgs/VisaCard";
import * as defaultProps from "./svgs/defaultProps";
import { IN3OIconType, IN3oIconProps } from "./types";

type IN3OIconProps = {
  [key in IN3OIconType]: FC<IN3oIconProps | AntdIconProps>;
};

export const N3oIcon: FC<IN3oIconProps> = (props) => {
  const {
    icontype,
    fill,
    height,
    width,
    heightAndWidth,
    ...remainingProps
  } = props;
  const { appId, theme } = useAppContext();

  let iconFill = fill;
  if (!iconFill) {
    iconFill = theme.primaryColor;
  }

  const n3oIcons: IN3OIconProps = {
    "n3o-account": (props: any) => {
      const iconProps = _.omit(props, ["appId", "appTheme"]);

      return (
        <UserOutlined
          {...iconProps}
          style={{
            fontSize: iconProps.width,
            color: iconProps.fill,
          }}
        />
      );
    },
    "n3o-account-create": CreateAccount,
    "n3o-account-data": AccountData,
    "n3o-account-preference": AccountPreference,
    "n3o-account-search": SearchAccount,
    "n3o-account-view": ViewAccount,
    "n3o-accounts-graphs": (props: any) => {
      const iconProps = _.omit(props, ["appId", "appTheme"]);

      return (
        <PieChartOutlined
          {...iconProps}
          style={{
            fontSize: iconProps.width,
            color: iconProps.fill,
          }}
        />
      );
    },
    "n3o-allocation": (props: any) => {
      const iconProps = _.omit(props, ["appId", "appTheme"]);

      return (
        <FolderOpenOutlined
          {...iconProps}
          style={{
            fontSize: iconProps.width,
            color: iconProps.fill,
          }}
        />
      );
    },
    "n3o-analytics": (props: any) => {
      const iconProps = _.omit(props, ["appId", "appTheme"]);

      return (
        <PieChartOutlined
          {...iconProps}
          style={{
            fontSize: iconProps.width,
            color: iconProps.fill,
          }}
        />
      );
    },
    "n3o-communications": (props: any) => {
      const iconProps = _.omit(props, ["appId", "appTheme"]);
      return (
        <InboxOutlined
          {...iconProps}
          style={{
            fontSize: iconProps.width,
            color: iconProps.fill,
          }}
        />
      );
    },
    "n3o-analytics-dashboard": AnalyticsDashboard,
    "n3o-analytics-graph": AnalyticsGraph,
    "n3o-app-suite": AppSuite,
    "n3o-bookmark": Bookmark,
    "n3o-call": PickUpCall,
    "n3o-call-data": PickUpCall,
    "n3o-callback": Callback,
    "n3o-callcenter": Callcenter,
    "n3o-callcenter-headphone": CallCenterHeadPhone,
    "n3o-call-campaign": CallCampaign,
    "n3o-call-history": CallHistory,
    "n3o-call-record": CallRecord,
    "n3o-call-records": CallRecords,
    "n3o-card-americanExpress": AmericanExpressCard,
    "n3o-card-dinersClub": DinersClubCard,
    "n3o-card-discover": DiscoverCard,
    "n3o-card-jcb": JCBCard,
    "n3o-card-mastercard": MastercardCard,
    "n3o-card-unionPay": UnionPayCard,
    "n3o-card-unknown": UnknownCard,
    "n3o-card-visa": VisaCard,
    "n3o-close-account": DeleteAccount,
    "n3o-comms": Comms,
    "n3o-correspondence-data": CustomCorrespondenceData,
    "n3o-send-custom-correspondence": (props: any) => {
      const iconProps = _.omit(props, ["appId", "appTheme"]);

      return (
        <SendOutlined
          {...iconProps}
          style={{
            fontSize: iconProps.width,
            color: iconProps.fill,
          }}
        />
      );
    },
    "n3o-contact-center": ContactCenter,
    "n3o-credential": (props: any) => {
      const iconProps = _.omit(props, ["appId", "appTheme"]);

      return (
        <CreditCardOutlined
          {...iconProps}
          style={{
            fontSize: iconProps.width,
            color: iconProps.fill,
          }}
        />
      );
    },
    "n3o-csv": Csv,
    "n3o-data-export": DataExport,
    "n3o-delete-account": DeleteAccount,
    "n3o-donation": GivingData,
    "n3o-donation-data": GivingData,
    "n3o-donation-item": ViewDonation,
    "n3o-donation-search": SearchDonation,
    "n3o-donation-view": ViewDonation,
    "n3o-donor-care": DonorCare,
    "n3o-double-arrow-box-down": DoubleArrowBoxDown,
    "n3o-double-arrow-box-up": DoubleArrowBoxUp,
    "n3o-due-date": DueDate,
    "n3o-download": (props: any) => {
      const iconProps = _.omit(props, ["appId", "appTheme"]);

      return (
        <DownloadOutlined
          {...iconProps}
          style={{
            fontSize: iconProps.width,
            color: iconProps.fill,
          }}
        />
      );
    },
    "n3o-edit": EditLinkIcon,
    "n3o-email": (props: any) => {
      const iconProps = _.omit(props, ["appId", "appTheme"]);

      return (
        <MailOutlined
          {...iconProps}
          style={{
            fontSize: iconProps.width,
            color: iconProps.fill,
          }}
        />
      );
    },
    "n3o-empty-chart": EmptyChart,
    "n3o-end-call": EndCall,
    "n3o-end-shift": EndShift,
    "n3o-engage-icon": EngageIcon,
    "n3o-enter-donation": CreateDonation,
    "n3o-external-link": ExternalLink,
    "n3o-feedback": FeedbackCenter,
    "n3o-feedback-data": FeedbackCenter,
    "n3o-feedback-center": FeedbackCenter,
    "n3o-feedbacks": Feedbacks,
    "n3o-feedbacks-create": FeedbackCreate,
    "n3o-feedbacks-data": FeedbackData,
    "n3o-feedbacks-renew": FeedbackRenew,
    "n3o-feedbacks-report": FeedbackReport,
    "n3o-feedbacks-search": FeedbackSearch,
    "n3o-feedbacks-stop-cancel": FeedbackStopCancel,
    "n3o-giving": (props: any) => {
      const iconProps = _.omit(props, ["appId", "appTheme"]);

      return (
        <HeartOutlined
          {...iconProps}
          style={{
            fontSize: iconProps.width,
            color: iconProps.fill,
          }}
        />
      );
    },
    "n3o-giving-search": SearchDonation,
    "n3o-giving-data": GivingData,
    "n3o-inbound-call": InboundCall,
    "n3o-json": Json,
    "n3o-mailchimp": Mailchimp,
    "n3o-mailroom": Mailroom,
    "n3o-merge-account": MergeAccount,
    "n3o-on-break": OnBreak,
    "n3o-outbound-call": OutboundCall,
    "n3o-outbox": Outbox,
    "n3o-payment": PaymentCash,
    "n3o-payment-agency-income": PaymentAgencyIncome,
    "n3o-payment-bank-transfer": PaymentBankTransfer,
    "n3o-payment-cash": PaymentCash,
    "n3o-payment-cheque": PaymentCheque,
    "n3o-payment-credit-card": PaymentCreditCard,
    "n3o-payment-direct-debit": PaymentDirectDebit,
    "n3o-payment-reconciliation": PaymentReconciliation,
    "n3o-payment-change": PaymentChange,
    "n3o-payment-deactivate": PaymentCardDeactivate,
    "n3o-payments": (props: any) => {
      const iconProps = _.omit(props, ["appId", "appTheme"]);

      return (
        <CreditCardOutlined
          {...iconProps}
          style={{
            fontSize: iconProps.width,
            color: iconProps.fill,
          }}
        />
      );
    },
    "n3o-payment-search": SearchPayments,
    "n3o-pickup-call": PickUpCall,
    "n3o-pledge": PledgeIcon,
    "n3o-pledge-data": PledgeAllocation,
    "n3o-pledge-search": PledgeSearchIcon,
    "n3o-pledge-view": (props: any) => {
      const iconProps = _.omit(props, ["appId", "appTheme"]);

      return (
        <FileTextOutlined
          {...iconProps}
          style={{
            fontSize: iconProps.width,
            color: iconProps.fill,
          }}
        />
      );
    },
    "n3o-pledge-create": (props: any) => {
      const iconProps = _.omit(props, ["appId", "appTheme"]);

      return (
        <FileTextOutlined
          {...iconProps}
          style={{
            fontSize: iconProps.width,
            color: iconProps.fill,
          }}
        />
      );
    },
    "n3o-pledge-update": (props: any) => {
      const iconProps = _.omit(props, ["appId", "appTheme"]);

      return (
        <FileTextOutlined
          {...iconProps}
          style={{
            fontSize: iconProps.width,
            color: iconProps.fill,
          }}
        />
      );
    },
    "n3o-pledge-delete": (props: any) => {
      const iconProps = _.omit(props, ["appId", "appTheme"]);

      return (
        <FileTextOutlined
          {...iconProps}
          style={{
            fontSize: iconProps.width,
            color: iconProps.fill,
          }}
        />
      );
    },
    "n3o-post": Post,
    "n3o-printing": (props: any) => {
      const iconProps = _.omit(props, ["appId", "appTheme"]);

      return (
        <PrinterOutlined
          {...iconProps}
          style={{
            fontSize: iconProps.width,
            color: iconProps.fill,
          }}
        />
      );
    },
    "n3o-refund-payment": RefundPayment,
    "n3o-reports": (props: any) => {
      const iconProps = _.omit(props, ["appId", "appTheme"]);

      return (
        <PieChartOutlined
          {...iconProps}
          style={{
            fontSize: iconProps.width,
            color: iconProps.fill,
          }}
        />
      );
    },
    "n3o-report-dashboard": ReportDashboard,
    "n3o-regular-giving": RegularGiving,
    "n3o-regular-giving-data": RegularGiving,
    "n3o-remind-date": RemindDate,
    "n3o-resume-call": ResumeCall,
    "n3o-role-data": (props: any) => {
      const iconProps = _.omit(props, ["appId", "appTheme"]);

      return (
        <ProfileOutlined
          {...iconProps}
          style={{
            fontSize: iconProps.width,
            color: iconProps.fill,
          }}
        />
      );
    },
    "n3o-round-pointing-arrow-top-right": RoundPointingArrowTopRight,
    "n3o-sendGrid": SendGrid,
    "n3o-sendy": Sendy,
    "n3o-sponsorship-search": SearchSponsorship,
    "n3o-snooze": Snooze,
    "n3o-sponsorship": Sponsorship,
    "n3o-sponsorship-beneficiary": SponsorshipBeneficiary,
    "n3o-sponsorship-create": CreateSponsorship,
    "n3o-sponsorship-contribution": PaymentCash,
    "n3o-sponsorship-data": SponsorshipData,
    "n3o-sponsorship-renew": SponsorshipRenew,
    "n3o-sponsorship-report": SponsorshipReport,
    "n3o-sponsorship-stop": SponsorshipStop,
    "n3o-sponsorship-transfer": SponsorshipTransfer,
    "n3o-stats-down": StatsDown,
    "n3o-stats-up": StatsUp,
    "n3o-tally-icon": TallyIcon,
    "n3o-task": Task,
    "n3o-task-create": CreateTask,
    "n3o-task-delete": DeleteTask,
    "n3o-task-empty": EmptyTask,
    "n3o-task-note": TaskNote,
    "n3o-tax-relief": TaxRelief,
    "n3o-tax-relief-submission": TaxReliefSubmission,
    "n3o-transform": (props: any) => {
      const iconProps = _.omit(props, ["appId", "appTheme"]);

      return (
        <RetweetOutlined
          {...iconProps}
          style={{
            fontSize: iconProps.width,
            color: iconProps.fill,
          }}
        />
      );
    },
    "n3o-transform-data": (props: any) => {
      const iconProps = _.omit(props, ["appId", "appTheme"]);

      return (
        <RetweetOutlined
          {...iconProps}
          style={{
            fontSize: iconProps.width,
            color: iconProps.fill,
          }}
        />
      );
    },
    "n3o-under-construction": UnderConstruction,
    "n3o-view-logs": ViewLogs,
    "n3o-upload": (props: any) => {
      const iconProps = _.omit(props, ["appId", "appTheme"]);

      return (
        <UploadOutlined
          {...iconProps}
          style={{
            fontSize: iconProps.width,
            color: iconProps.fill,
          }}
        />
      );
    },
    "n3o-users": (props: any) => {
      const iconProps = _.omit(props, ["appId", "appTheme"]);

      return (
        <TeamOutlined
          {...iconProps}
          style={{
            fontSize: iconProps.width,
            color: iconProps.fill,
          }}
        />
      );
    },
    "n3o-user": (props: any) => {
      const iconProps = _.omit(props, ["appId", "appTheme"]);

      return (
        <TeamOutlined
          {...iconProps}
          style={{
            fontSize: iconProps.width,
            color: iconProps.fill,
          }}
        />
      );
    },
    "n3o-url": (props: any) => {
      const iconProps = _.omit(props, ["appId", "appTheme"]);
      return (
        <LinkOutlined
          {...iconProps}
          style={{
            fontSize: iconProps.width,
            color: iconProps.fill,
          }}
        />
      );
    },
    "n3o-user-data": (props: any) => {
      const iconProps = _.omit(props, ["appId", "appTheme"]);

      return (
        <TeamOutlined
          {...iconProps}
          style={{
            fontSize: iconProps.width,
            color: iconProps.fill,
          }}
        />
      );
    },
    "n3o-spinner": (props: any) => {
      const iconProps = _.omit(props, ["appId", "appTheme"]);

      return (
        <LoadingOutlined
          {...iconProps}
          style={{
            fontSize: iconProps.width,
            color: iconProps.fill,
          }}
        />
      );
    },
    "n3o-file-done": (props: any) => {
      const iconProps = _.omit(props, ["appId", "appTheme"]);

      return (
        <FileDoneOutlined
          {...iconProps}
          style={{
            fontSize: iconProps.width,
            color: iconProps.fill,
          }}
        />
      );
    },
    "n3o-send-donations-statement": (props: any) => {
      const iconProps = _.omit(props, ["appId", "appTheme"]);

      return (
        <SendStatement
          {...iconProps}
          style={{
            fontSize: iconProps.width,
            color: iconProps.fill,
          }}
        />
      );
    },
    "n3o-correspondence-create": (props: any) => {
      const iconProps = _.omit(props, ["appId", "appTheme"]);

      return (
        <SendOutlined
          {...iconProps}
          style={{
            fontSize: iconProps.width,
            color: iconProps.fill,
          }}
        />
      );
    },
    "n3o-activities-search": (props: any) => {
      const iconProps = _.omit(props, ["appId", "appTheme"]);
      return (
        <HistoryOutlined
          {...iconProps}
          style={{
            fontSize: iconProps.width,
            color: iconProps.fill,
          }}
        />
      );
    },
    // add more custom icons here, but please try to keep them in alphabetical order :)
  };

  if (n3oIcons[icontype]) {
    const iconProps = _.omit(props, ["appId", "appTheme", "heightAndWidth"]);

    return (
      <Icon
        component={() =>
          n3oIcons[icontype]({
            ...remainingProps,
            fill: iconFill,
            appId,
            appTheme: theme,
            height: height || heightAndWidth || defaultProps.height,
            width: width || heightAndWidth || defaultProps.width,
          })
        }
        {...iconProps}
      />
    );
  } else {
    return <></>;
  }
};

// icon usage example:
// <N3oIcon icontype="n3o-account" />
// <N3oIcon icontype="n3o-antdesign-user" />
// <Icon type="antdesign-user" />
