import {
  ReportingPeriodRes,
  ReportingPeriodResultsPage,
} from "@n3oltd/karakoram.analytics.sdk.attribution/src/index";
import { all, put, takeEvery, takeLatest } from "redux-saga/effects";

import { _attributionClient } from "appRedux/models/base/K2RestClients";
import K2RestService from "appRedux/models/base/K2RestService";
import { IApiResponse } from "appRedux/models/common/ApiResponseModel";
import { DataActions } from "appRedux/modules/sharedTypes";

import {
  ActionTypes,
  CreateReportingPeriod,
  DeleteReportingPeriod,
  FindReportingPeriods,
  UpdateReportingPeriod,
  UpdateSortOrder,
} from "./actionTypes";
import * as actions from "./actions";

//#region WORKERS

function* findReportingPeriods(action: FindReportingPeriods) {
  const resp: IApiResponse<ReportingPeriodResultsPage> = yield K2RestService.toResponse(
    _attributionClient.findReportingPeriods(action.criteria),
  );

  if (resp.error) {
    yield put(actions.findReportingPeriodsError(resp.error));
  } else {
    yield put(
      actions.findReportingPeriodsSuccess(
        resp.getResultOrDefault(),
        !!action.criteria.continuationToken,
      ),
    );
  }
}

function* updateSortOrder(action: UpdateSortOrder) {
  const resp: IApiResponse<void> = yield K2RestService.toResponse(
    _attributionClient.updateReportingPeriodOrder(action.revisionId, {
      order: action.newOrder,
    }),
  );

  if (resp.error) {
    yield put(
      actions.updateSortOrderError({
        action: DataActions.update,
        ...resp,
      }),
    );
  } else {
    yield put(
      actions.updateSortOrderSuccess({
        action: DataActions.update,
        ...resp,
      }),
    );
  }
}

function* createReportingPeriod(action: CreateReportingPeriod) {
  const resp: IApiResponse<ReportingPeriodRes> = yield K2RestService.toResponse(
    _attributionClient.createReportingPeriod(action.data),
  );

  if (resp.error) {
    yield put(
      actions.createReportingPeriodError({
        action: DataActions.add,
        ...resp,
      }),
    );
  } else {
    yield put(
      actions.createReportingPeriodSuccess(resp.getResultOrDefault(), {
        action: DataActions.add,
        ...resp,
      }),
    );
  }
}

function* updateReportingPeriod(action: UpdateReportingPeriod) {
  const resp: IApiResponse<void> = yield K2RestService.toResponse(
    _attributionClient.updateReportingPeriod(action.revisionId, action.data),
  );

  if (resp.error) {
    yield put(
      actions.updateReportingPeriodError({
        action: DataActions.update,
        ...resp,
      }),
    );
  } else {
    yield put(
      actions.updateReportingPeriodSuccess({
        action: DataActions.update,
        ...resp,
      }),
    );
  }
}

function* deleteReportingPeriod(action: DeleteReportingPeriod) {
  const resp: IApiResponse<void> = yield K2RestService.toResponse(
    _attributionClient.deleteReportingPeriod(action.revisionId),
  );

  if (resp.error) {
    yield put(
      actions.deleteReportingPeriodError({
        action: DataActions.delete,
        ...resp,
      }),
    );
  } else {
    yield put(
      actions.deleteReportingPeriodSuccess(
        {
          action: DataActions.delete,
          ...resp,
        },
        action.id,
      ),
    );
  }
}

//#endregion

//#region WATCHERS

export default function* reportingPeriodsSaga() {
  yield all([
    takeLatest(ActionTypes.FIND_REPORTING_PERIODS, findReportingPeriods),
    takeLatest(ActionTypes.CREATE_REPORTING_PERIOD, createReportingPeriod),
    takeLatest(ActionTypes.UPDATE_REPORTING_PERIOD, updateReportingPeriod),
    takeEvery(ActionTypes.UPDATE_SORT_ORDER, updateSortOrder),
    takeLatest(ActionTypes.DELETE_REPORTING_PERIOD, deleteReportingPeriod),
  ]);
}
//#endregion
