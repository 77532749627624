import React, { FC } from "react";
import { DeleteOutlined } from "@ant-design/icons";
import styled from "styled-components";
import { K2Permission } from "components/k2Widgets";

const RedDeleteOutlined = styled(DeleteOutlined)`
  && {
    color: ${({ theme }) => theme.danger_color};
    &:hover {
      opacity: 0.7;
    }
  }
`;

type DeleteProps = Omit<typeof DeleteOutlined, "$$typeof">;

interface DeleteIconProps extends DeleteProps {
  tooltipKey?: string;
  className?: string;
  permission?: string;

  onClick: () => void;
}

const DeleteIcon: FC<DeleteIconProps> = (props) => {
  const { tooltipKey, permission, ...remainingProps } = props;

  return (
    <K2Permission
      titleKey={tooltipKey || "common.delete"}
      permission={permission}
    >
      <RedDeleteOutlined {...remainingProps} />
    </K2Permission>
  );
};

export default DeleteIcon;
