import React, { FC, useState } from "react";

import { PlusOutlined } from "@ant-design/icons";
import { DataScope } from "@n3oltd/karakoram.analytics.sdk.attribution";
import { Space } from "antd";

import { K2Button, K2Message } from "components/k2Widgets";
import K2Drawer from "components/k2Widgets/k2Drawer";
import ReportingPeriods from "routes/admin/analytics/reportingPeriods";

interface IReportingPeriodDrawer {
  showManageReporting: boolean;
  setShowManageReporting: (value: boolean) => void;
  refetchReportingPeriod: () => void;
}
export const ReportingPeriodDrawer: FC<IReportingPeriodDrawer> = ({
  showManageReporting,
  setShowManageReporting,
  refetchReportingPeriod,
}) => {
  const [showAddReportingModal, setShowAddReportingModal] = useState<boolean>(
    false,
  );
  return (
    <K2Drawer
      placement="right"
      destroyOnClose={true}
      width={"60%"}
      visible={showManageReporting}
      push={false}
      closable={false}
      title={
        <div
          style={{
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
          }}
        >
          <span>
            <K2Message localeKey={"analytics.manage.reportingPeriod"} />
          </span>
          <Space>
            <K2Button
              localeKey={"common.close"}
              type="default"
              onClick={() => setShowManageReporting(false)}
            />
            <K2Button
              type={"primary"}
              onClick={() => setShowAddReportingModal(true)}
            >
              <Space size={"small"}>
                <PlusOutlined />
                <K2Message localeKey={"admin.reportingPeriods.add"} />
              </Space>
            </K2Button>
          </Space>
        </div>
      }
    >
      <ReportingPeriods
        reportingInfo={{
          reportingScope: DataScope.Private,
          showAddReportingModal: showAddReportingModal,
          setShowAddReportingModal: setShowAddReportingModal,
          refetch: refetchReportingPeriod,
        }}
      />
    </K2Drawer>
  );
};
