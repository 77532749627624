import {
  ReportingPeriodCriteria,
  ReportingPeriodReq,
  ReportingPeriodRes,
  ReportingPeriodResultsPage,
} from "@n3oltd/karakoram.analytics.sdk.attribution/esm";

import { ServerError } from "appRedux/models/common/ApiResponseModel";
import { EditStatus } from "appRedux/modules/sharedTypes";
import {
  ActionTypes,
  ReportingPeriodAction,
} from "routes/admin/analytics/reportingPeriods/modules/actionTypes";

export const findReportingPeriods = (
  criteria: ReportingPeriodCriteria,
): ReportingPeriodAction => ({
  type: ActionTypes.FIND_REPORTING_PERIODS,
  criteria,
});

export const findReportingPeriodsSuccess = (
  data: ReportingPeriodResultsPage,
  shouldAppendToList: boolean,
): ReportingPeriodAction => ({
  type: ActionTypes.FIND_REPORTING_PERIODS_SUCCESS,
  data,
  shouldAppendToList,
});

export const findReportingPeriodsError = (
  error: ServerError,
): ReportingPeriodAction => ({
  type: ActionTypes.FIND_REPORTING_PERIODS_ERROR,
  error,
});

export const clearReportingPeriodsError = (): ReportingPeriodAction => ({
  type: ActionTypes.CLEAR_REPORTING_PERIODS_ERROR,
});

export const createReportingPeriod = (
  data: ReportingPeriodReq,
): ReportingPeriodAction => ({
  type: ActionTypes.CREATE_REPORTING_PERIOD,
  data,
});

export const createReportingPeriodSuccess = (
  data: ReportingPeriodRes,
  editStatus: EditStatus,
): ReportingPeriodAction => ({
  type: ActionTypes.CREATE_REPORTING_PERIOD_SUCCESS,
  data,
  editStatus,
});

export const createReportingPeriodError = (
  editStatus: EditStatus,
): ReportingPeriodAction => ({
  type: ActionTypes.CREATE_REPORTING_PERIOD_ERROR,
  editStatus,
});

export const clearEditStatus = (): ReportingPeriodAction => ({
  type: ActionTypes.CLEAR_REPORTING_PERIODS_EDIT_STATUS,
});

export const setSelectedReportingPeriod = (
  selectedReportingPeriod?: ReportingPeriodRes,
): ReportingPeriodAction => ({
  type: ActionTypes.SET_SELECTED_REPORTING_PERIOD,
  selectedReportingPeriod,
});

export const updateReportingPeriod = (
  revisionId: string,
  data: ReportingPeriodReq,
): ReportingPeriodAction => ({
  type: ActionTypes.UPDATE_REPORTING_PERIOD,
  revisionId,
  data,
});

export const updateReportingPeriodSuccess = (
  editStatus: EditStatus<void>,
): ReportingPeriodAction => ({
  type: ActionTypes.UPDATE_REPORTING_PERIOD_SUCCESS,
  editStatus,
});

export const updateReportingPeriodError = (
  editStatus: EditStatus<void>,
): ReportingPeriodAction => ({
  type: ActionTypes.UPDATE_REPORTING_PERIOD_ERROR,
  editStatus,
});

export const clearSelectedEditStatus = (): ReportingPeriodAction => ({
  type: ActionTypes.CLEAR_SELECTED_REPORTING_PERIODS_EDIT_STATUS,
});

export const updateSortOrder = (
  revisionId: string,
  newOrder: number,
): ReportingPeriodAction => ({
  type: ActionTypes.UPDATE_SORT_ORDER,
  revisionId,
  newOrder,
});

export const updateSortOrderSuccess = (
  editStatus: EditStatus<void>,
): ReportingPeriodAction => ({
  type: ActionTypes.UPDATE_SORT_ORDER_SUCCESS,
  editStatus,
});

export const updateSortOrderError = (
  editStatus: EditStatus<void>,
): ReportingPeriodAction => ({
  type: ActionTypes.UPDATE_SORT_ORDER_ERROR,
  editStatus,
});

export const deleteReportingPeriod = (
  revisionId: string,
  id: string,
): ReportingPeriodAction => ({
  type: ActionTypes.DELETE_REPORTING_PERIOD,
  revisionId,
  id,
});

export const deleteReportingPeriodSuccess = (
  editStatus: EditStatus<void>,
  id: string,
): ReportingPeriodAction => ({
  type: ActionTypes.DELETE_REPORTING_PERIOD_SUCCESS,
  editStatus,
  id,
});

export const deleteReportingPeriodError = (
  editStatus: EditStatus<void>,
): ReportingPeriodAction => ({
  type: ActionTypes.DELETE_REPORTING_PERIOD_ERROR,
  editStatus,
});
