import React, { useState } from "react";

import {
  CalendarOutlined,
  InfoCircleFilled,
  MailOutlined,
} from "@ant-design/icons";
import { AppointmentRes, CallRes } from "@n3oltd/k2.calls.sdk.agents";
import { Button } from "antd";
import { useRouter } from "hooks";
import styled from "styled-components";

import { K2Routes } from "appRedux/models/routes/K2Routes";
import { useAppContext } from "common/contexts/AppProvider/AppProvider";
import variables from "common/themeVariables";
import NextAccountToCallSummary from "components/callCenter/dialler/components/NextAccountToCallSummary";
import { K2Message } from "components/k2Widgets";
import injectK2Intl from "components/k2Widgets/k2Localizations/injectK2Intl";
import { InjectedK2IntlProps } from "components/k2Widgets/k2Localizations/types";
import { N3oIcon } from "components/n3oIcon";
import { useGetCustomCorrespondenceLinks } from "hooks/communications";
import SendCorrespondence from "routes/accounts/profile/components/SendCorrespondence";

import { AccountInfo, AppointmentPreview } from "../../types";
import TitleDivider from "../TitleDivider";

const CallingHeader = styled.div`
  background-color: ${({ theme }) => theme.green_6};
  color: ${({ theme }) => theme.white_color};
  padding: 10px 15px;
  border-radius: 10px 10px 0 0;
  display: flex;
  justify-content: space-between;
  font-weight: ${({ theme }) => theme.font_weight_bold};
`;

const NextAccountToCallSummaryWrapper = styled.div`
  padding: 20px;
  padding-bottom: 0px;
`;
const CallingBody = styled.div`
  padding: 0px 20px;
  overflow: hidden;
  & > p {
    margin: 0px 0 20px 0;
    overflow: hidden;
    white-space: nowrap;
    text-align: center;
    color: ${({ theme }) => theme.grey_9};
    font-weight: ${({ theme }) => theme.font_weight_semi_bold};
  }
`;

const CallingButtons = styled.div`
  background-color: ${({ theme }) => theme.grey_2};
  padding: 15px;
  display: flex;
  justify-content: center;
  align-items: flex-start;
  text-align: center;

  p {
    margin: 10px 0 0 0;
    color: ${({ theme }) => theme.grey_7};
    font-size: ${({ theme }) => theme.font_size_sm};
  }

  div {
    width: 33.33%;

    &:last-child button {
      background-color: ${({ theme }) => theme.red_5} !important;

      &:hover {
        background-color: ${({ theme }) => theme.red_6} !important;
      }

      span {
        margin-top: 6px;
      }
    }
  }
`;

const CallingButton = styled.button<{
  $background?: string;
  $backgroundHover?: string;
}>`
  border: 0 none;
  background-color: ${({ $background }) => $background};
  color: ${({ theme }) => theme.white_color};
  height: 50px;
  width: 50px;
  border-radius: 100%;
  font-size: 18px;
  transition: 0.25s;

  &:hover {
    background-color: ${({ $backgroundHover }) => $backgroundHover};
  }
`;

const CallingNotice = styled.div`
  border-radius: 0 0 10px 10px;
  color: ${({ theme }) => theme.white_color};
  background-color: rgba(73, 75, 92, 0.95);
  display: flex;
  flex-direction: column;
  justify-content: center;
  text-align: center;
  align-items: center;
  position: absolute;
  padding: 0 30px;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 2;

  div {
    display: flex;
    margin-top: 20px;
  }
`;

const Icon = styled.span`
  display: flex;
  align-items: center;
`;

interface CallingViewProps extends InjectedK2IntlProps {
  callDuration: string;
  callingAccount: CallRes;
  longCallDismiss: boolean;
  appointment: AppointmentRes;
  setOpenAppointmentModal: (value: boolean) => void;
  setEndCallView: () => void;
  onResume: () => void;
}

function CallingView({
  callDuration,
  callingAccount,
  longCallDismiss,
  k2Intl,
  appointment,
  setOpenAppointmentModal,
  setEndCallView,
  onResume,
}: CallingViewProps) {
  const { theme } = useAppContext();
  const router = useRouter<{
    accountId: string;
  }>();

  const [
    displayCustomCorrespondence,
    setDisplayCustomCorrespondence,
  ] = useState<boolean>(false);

  const callingAccountId = callingAccount?.account?.id;
  const [customCorrespondenceLinks] = useGetCustomCorrespondenceLinks(
    callingAccountId,
    k2Intl,
  );

  const [
    correspondenceModalOpen,
    setCorrespondenceModalOpen,
  ] = useState<boolean>(false);

  const nextAccountInfo: AccountInfo = {
    ...callingAccount?.account,
    phoneNumber: callingAccount?.phoneNumber,
  };

  const appointmentInfo: AppointmentPreview = callingAccount.hasAppointment
    ? {
        scheduledOn: appointment?.dueAt,
        notes: appointment?.notes,
        assignedTo: appointment?.assignedTo?.name,
      }
    : null;
  const campaign = callingAccount.hasAppointment
    ? appointment?.campaign
    : callingAccount.campaign;
  return (
    <>
      <CallingHeader>
        <span>
          <K2Message localeKey="communication.cc.dialler.onCall" />
        </span>
        <Icon>
          <span style={{ marginRight: 10 }}>{callDuration}</span>
          <span style={{ lineHeight: 0 }}>
            <N3oIcon
              height={20}
              width={20}
              icontype={"n3o-outbound-call"}
              fill="white"
            />
          </span>
        </Icon>
      </CallingHeader>
      <>
        <NextAccountToCallSummaryWrapper>
          <NextAccountToCallSummary
            isTransparent={true}
            account={nextAccountInfo}
            appointmentInfo={appointmentInfo}
          />
        </NextAccountToCallSummaryWrapper>

        <CallingBody>
          <TitleDivider localeKey="communication.cc.dialler.callingFor" />
          <p>{campaign?.name}</p>
        </CallingBody>
        <CallingButtons>
          <div>
            <CallingButton
              type="button"
              onClick={() => {
                if (customCorrespondenceLinks.length > 0) {
                  setDisplayCustomCorrespondence(false);
                  setCorrespondenceModalOpen(true);
                } else {
                  router.history.push(
                    `${K2Routes.sendFromOutboxes}?accountId=${callingAccountId}`,
                  );
                }
              }}
              $background={variables.blue_6}
              $backgroundHover={variables.button_hover_color}
            >
              <MailOutlined />
            </CallingButton>
            <p>
              <K2Message localeKey="communication.cc.dialler.sendEmail" />
            </p>
          </div>
          <div>
            <CallingButton
              type="button"
              onClick={() => setOpenAppointmentModal(true)}
              $background={variables.blue_6}
              $backgroundHover={variables.button_hover_color}
            >
              <CalendarOutlined />
            </CallingButton>
            <p>
              <K2Message localeKey="communication.cc.dialler.scheduleAppointment" />
            </p>
          </div>
          <div>
            <CallingButton type="button" onClick={() => setEndCallView()}>
              <N3oIcon icontype="n3o-end-call" fill={theme.white_color} />
            </CallingButton>
            <p>
              <K2Message localeKey="communication.cc.dialler.endCall" />
            </p>
          </div>
        </CallingButtons>
        {longCallDismiss === true && (
          <CallingNotice>
            <p>
              <InfoCircleFilled
                style={{ fontSize: "36px", color: theme.gold_6 }}
              />
            </p>
            <p>
              <K2Message localeKey="communication.cc.dialler.onLongCall" />
            </p>
            <p>
              <K2Message localeKey="communication.cc.dialler.stillOnCall" />
            </p>
            <div>
              <Button type="primary" onClick={() => onResume()}>
                <K2Message localeKey="communication.cc.dialler.continueCall" />
              </Button>
              <Button type="primary" onClick={() => setEndCallView()} danger>
                <K2Message localeKey="communication.cc.dialler.endCall" />
              </Button>
            </div>
          </CallingNotice>
        )}
      </>
      {correspondenceModalOpen && (
        <SendCorrespondence
          setCorrespondenceModalOpen={setCorrespondenceModalOpen}
          setDisplayCustomCorrespondence={setDisplayCustomCorrespondence}
          customCorrespondenceLinks={customCorrespondenceLinks}
          displayCustomCorrespondence={displayCustomCorrespondence}
          accountId={callingAccountId}
        />
      )}
    </>
  );
}

export default injectK2Intl(CallingView);
