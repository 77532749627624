import React, { FC, useCallback, useEffect, useState } from "react";

import {
  ArrowLeftOutlined,
  LeftOutlined,
  RightOutlined,
} from "@ant-design/icons";
import {
  AccessReq,
  BookmarkReq,
  BookmarkRes,
  PinnedVisualReq,
  PinningLocation,
} from "@n3oltd/karakoram.analytics.sdk.reports/esm";
import { Col, Form, Input, Layout, Row, Skeleton, Spin } from "antd";
import { usePrevious, useWindowWidth } from "hooks";
import _ from "lodash";
import * as pbi from "powerbi-client";
import { TokenType } from "powerbi-models";
import { connect } from "react-redux";
import { RouteComponentProps } from "react-router-dom";

import { _reportsClient } from "appRedux/models/base/K2RestClients";
import K2RestService from "appRedux/models/base/K2RestService";
import {
  IApiResponse,
  K2StatusCodes,
} from "appRedux/models/common/ApiResponseModel";
import { errorKeys } from "appRedux/models/common/Constants";
import { Q1Routes } from "appRedux/models/routes/Q1RouteModel";
import { K2Routes } from "appRedux/models/routes/RouteModel";
import { BreadcrumbConfig } from "appRedux/modules/breadcrumbs/types";
import AnalyticsSelectors, {
  IGetPinnedReportsStateProps,
} from "appRedux/store/selectors/AnalyticsSelectors";
import { useAppContext } from "common/contexts/AppProvider/AppProvider";
import Error404 from "components/errors/404";
import Error500 from "components/errors/500";
import {
  K2Button,
  K2Card,
  K2Link,
  K2Message,
  K2RouteHeading,
} from "components/k2Widgets";
import injectK2Intl from "components/k2Widgets/k2Localizations/injectK2Intl";
import { InjectedK2IntlProps } from "components/k2Widgets/k2Localizations/types";
import {
  FormsWrapper,
  PageWithBreadcrumbsWrapper,
} from "components/layout/wrappers";
import { N3oIcon } from "components/n3oIcon";
import PowerBiEmbedded from "components/powerBiEmbedded";
import EditPermissionsModal from "components/sharedAccess/EditPermissionsModal";
import { UIUtils } from "components/utils";
import { showConfirm, showError } from "components/utils/Confirmation";
import DirtyDataGuard from "components/utils/DirtyDataGuard";
import { showNotification } from "components/utils/Notification";
import { MOBILE_SIZE } from "constants/ThemeSetting";
import { ReportingPeriodDrawer } from "containers/analytics/analysis/searchForms/ReportingPeriodDrawer";
import useBreadcrumb from "hooks/useBreadcrumb";

import BookmarksList from "./bookmarksList";
import EmptyBookmarks from "./emptyBookmarks";
import {
  BookmarkCollapseWrapper,
  BookmarksWrapper,
  RouteHeadingWrapper,
  SiderWrapper,
} from "./styled";

const formItemLayout = {
  labelCol: {
    md: 24,
  },
  wrapperCol: {
    md: 24,
  },
};

const {
  main: { analytics },
  embedReport,
} = K2Routes;

const {
  main: { q1Analytics },
  q1EmbedReport,
} = Q1Routes;

interface IProps
  extends RouteComponentProps<{
      reportId?: string;
    }>,
    IGetPinnedReportsStateProps,
    InjectedK2IntlProps {}

const PinVisuals: FC<IProps> = (props) => {
  const {
    match,
    loading,
    pinnedVisual,
    pinToDashboardLoading,
    deleteBookmarkLoading,
    captureBookmarkLoading,
    bookmarksLoading,
    capturedBookmark,
    captureBookmark,
    deleteBookmark,
    embedReportRes,
    allBookmarks,
    fetchAllBookmarks,
    fetchEmbeddedReport,
    pinVisualToDashboard,
    clearReportsAndVisuals,
    serverError,
    clearServerError,
    k2Intl,
  } = props;

  const reportId = match.params.reportId;
  const pinToDashboardCommandName = "pinToDashboard";

  const { theme, appId } = useAppContext();
  const [pinToDashboardForm] = Form.useForm();
  const [captureBookmarkForm] = Form.useForm();

  const [showCaptureUpdateModal, setShowCaptureUpdateModal] = useState<boolean>(
    false,
  );
  const [pinningVisual, setPinningVisual] = useState<boolean>(false);
  const [showShareModal, setShowShareModal] = useState<boolean>(false);
  const [sharedBookmark, setSharedBookmark] = useState<BookmarkRes>(null);
  const [showDeleteModal, setShowDeleteModal] = useState<boolean>(false);
  const [bookmarkId, setBookmarkId] = useState<string>(null);
  const [applyingBookmark, setApplyingBookmark] = useState<boolean>(false);
  const [reportLoaded, setReportLoaded] = useState<boolean>(false);
  const [bookmarksCollapsed, setBookmarksCollapsed] = useState<boolean>(true);
  const [dataChanged, setDataChanged] = useState<boolean>(false);
  const [embeddedReport, setEmbeddedReport] = useState<pbi.Report>(null);
  const [embeddedReportFilters, setEmbeddedReportFilters] = useState<
    pbi.models.IFilter[]
  >(null);
  const [pageChanged, setPageChanged] = useState<boolean>(false);
  const [showManageReporting, setShowManageReporting] = useState<boolean>(
    false,
  );
  const prevEmbeddedReport = usePrevious(embeddedReport);
  const prevPinToDashboardLoading = usePrevious(pinToDashboardLoading);
  const prevCaptureBookmarkLoading = usePrevious(captureBookmarkLoading);
  const prevDeleteBookmarkLoading = usePrevious(deleteBookmarkLoading);
  const prevPageChanged = usePrevious(pageChanged);

  const setBreadcrumbs = useBreadcrumb();

  useEffect(() => {
    let crumbs: BreadcrumbConfig[] = [
      {
        path: appId === "k2" ? analytics : q1Analytics,
        titleKey: "mainNav.analytics",
        active: false,
      },
      {
        path: appId === "k2" ? analytics : q1Analytics,
        titleKey: "reports",
        active: false,
      },
    ];

    if (embedReportRes?.name) {
      crumbs.push({
        path: appId === "k2" ? embedReport : q1EmbedReport,
        title: embedReportRes?.name,
        active: true,
      });
    }

    setBreadcrumbs(crumbs);
  }, [appId, embedReportRes, setBreadcrumbs]);

  useEffect(() => {
    let isCancelled = false;

    const getEmbedReport = () => {
      if (!isCancelled) {
        fetchEmbeddedReport(reportId);
      }
    };

    getEmbedReport();

    return () => {
      isCancelled = true;
      clearReportsAndVisuals();
      setDataChanged(false);
    };
  }, [reportId, fetchEmbeddedReport, clearReportsAndVisuals]);

  useEffect(() => {
    if (embeddedReport !== null && embeddedReportFilters === null) {
      embeddedReport?.getFilters()?.then((filters) => {
        setEmbeddedReportFilters(filters?.filter((x) => !_.isNil(x.target)));
      });
    }
  }, [embeddedReport, embeddedReportFilters]);

  useEffect(() => {
    if (
      prevPinToDashboardLoading !== pinToDashboardLoading &&
      !pinToDashboardLoading &&
      prevPinToDashboardLoading &&
      !_.isNil(pinnedVisual) &&
      !_.isEmpty(pinnedVisual)
    ) {
      showNotification({
        type: "success",
        title: k2Intl?.formatMessage({
          localeKey: "common.success.title",
        }),
        message: UIUtils.messageToHtml(
          k2Intl,
          "reports.pinToDashboard.success",
          { report: pinnedVisual?.title, pinnedTo: pinnedVisual?.pinnedTo },
        ),
      });

      setPinningVisual(false);
    }

    if (
      prevCaptureBookmarkLoading !== captureBookmarkLoading &&
      !captureBookmarkLoading &&
      prevCaptureBookmarkLoading &&
      !_.isNil(capturedBookmark) &&
      !_.isEmpty(capturedBookmark)
    ) {
      showNotification({
        type: "success",
        title: k2Intl?.formatMessage({
          localeKey: "common.success.title",
        }),
        message: UIUtils.messageToHtml(
          k2Intl,
          "reports.captureBookmark.success",
          { bookmark: capturedBookmark.name },
        ),
      });

      fetchAllBookmarks({ reportId: reportId });
      setBookmarkId(capturedBookmark.id);
    }

    if (
      prevDeleteBookmarkLoading !== deleteBookmarkLoading &&
      !deleteBookmarkLoading &&
      prevDeleteBookmarkLoading
    ) {
      showNotification({
        type: "success",
        title: k2Intl?.formatMessage({
          localeKey: "common.success.title",
        }),
        message: UIUtils.messageToHtml(
          k2Intl,
          "reports.bookmarks.delete.success",
          { bookmark: "Bookmark" },
        ),
      });

      fetchAllBookmarks({ reportId: reportId });
    }
  }, [
    captureBookmarkLoading,
    capturedBookmark,
    deleteBookmarkLoading,
    fetchAllBookmarks,
    k2Intl,
    pinToDashboardLoading,
    pinnedVisual,
    prevCaptureBookmarkLoading,
    prevDeleteBookmarkLoading,
    prevPinToDashboardLoading,
    reportId,
  ]);

  const commandTriggered = useCallback(
    (command: pbi.service.ICustomEvent<any>) => {
      const reportDetails = command.detail;

      if (reportDetails?.command === pinToDashboardCommandName) {
        const label = k2Intl?.formatMessage({
          localeKey: "reports.pinToDashboard.visualName.title",
        });

        const requiredError = k2Intl?.formatMessage({
          localeKey: errorKeys.requiredValidationError,
          values: { fieldName: label },
        });

        showConfirm({
          titleKey: "reports.pinToDashboard.title",
          content: (
            <Form
              form={pinToDashboardForm}
              scrollToFirstError
              layout={"vertical"}
              onFieldsChange={() => setDataChanged(true)}
              onFinishFailed={(errorInfo) => console.log("failed", errorInfo)}
            >
              <Row gutter={[12, 18]} className={"n3o-mb-0"}>
                <Col span={24}>
                  <Form.Item
                    label={label}
                    {...formItemLayout}
                    name={"title"}
                    rules={[{ required: true, message: requiredError }]}
                  >
                    <Input autoFocus />
                  </Form.Item>
                </Col>
              </Row>
            </Form>
          ),
          onOk: async () => {
            return new Promise(async (resolve, reject) => {
              try {
                setPinningVisual(true);

                const values = pinToDashboardForm?.getFieldsValue();

                const pages = await embeddedReport?.getPages();
                const pagesFilters = await embeddedReport?.getFilters();
                const currentPage = pages?.find(
                  (x) => x.name === reportDetails?.page?.name,
                );
                const visuals = await currentPage?.getVisuals();
                const currentVisual = visuals?.find(
                  (x) => x.name === reportDetails?.visual?.name,
                );
                const filters = await currentVisual?.getFilters();

                const req: PinnedVisualReq = {
                  title: values?.title,
                  visualName: reportDetails?.visual?.name,
                  pageName: currentPage?.name,
                  filtersJson: JSON.stringify([...filters, ...pagesFilters]),
                  pinnedTo: PinningLocation.Dashboard,
                };

                pinVisualToDashboard(reportId, req);
                pinToDashboardForm?.resetFields();
                setDataChanged(false);
                resolve(values);
              } catch (e) {
                setPinningVisual(false);
                reject(e);
              }
            }).catch(() => console.log("Oops errors!"));
          },
          onCancel: () => setDataChanged(false),
          okTextKey: "reports.pinToDashboard.pin",
          okButtonProps: { loading: pinToDashboardLoading },
          k2Intl,
        });
      }
    },
    [
      embeddedReport,
      pinToDashboardForm,
      k2Intl,
      pinToDashboardLoading,
      pinVisualToDashboard,
      reportId,
    ],
  );

  useEffect(() => {
    if (prevEmbeddedReport !== embeddedReport && embeddedReport !== null) {
      embeddedReport.on("commandTriggered", commandTriggered);

      embeddedReport.on("loaded", () => {
        setReportLoaded(true);
        fetchAllBookmarks({ reportId: reportId });
      });

      embeddedReport.on("pageChanged", (event: any) => {
        // console.log("PageChanged: ", event, event?.page?.name);
        setPageChanged(true);
      });

      //TODO: this might get fixed in next version so have to check again that is why keeping this code for future reference
      // embeddedReport.on("filtersApplied", (e) =>
      //   console.log("filtersApplied", e),
      // );

      // embeddedReport.on<{ eventName: string }>("rendered", (event) => {
      //   console.log("rendered", event);
      // });
    }
  }, [
    commandTriggered,
    embeddedReport,
    fetchAllBookmarks,
    prevEmbeddedReport,
    reportId,
  ]);

  useEffect(() => {
    if (serverError) {
      if (serverError.status === K2StatusCodes.preconditionFailed) {
        const nonFieldRelatedErrors = UIUtils.getOverallValidationErrors(
          serverError,
          "error",
          "fieldRelated",
        )?.map?.((err) => err.error);

        showNotification({
          type: "error",
          title: k2Intl?.formatMessage({
            localeKey: "common.nonDismissableFormErrorTitle",
          }),
          message: nonFieldRelatedErrors?.map?.((e, i) => <p key={i}>{e}</p>),
        });
      }
    }
  }, [clearServerError, k2Intl, serverError]);

  const setBookmark = useCallback(
    (id: string, stateJson: string, setDefaultFilters: boolean = false) => {
      setBookmarkId(id);
      setApplyingBookmark(true);

      const filters = setDefaultFilters
        ? embeddedReportFilters
        : (JSON.parse(stateJson) as pbi.models.IFilter[]);

      embeddedReport
        .setFilters(filters?.filter((x) => !_.isNil(x.target)))
        .then(() => setApplyingBookmark(false))
        .catch((errors) => {
          console.error(errors);
        });
    },
    [embeddedReport, embeddedReportFilters],
  );

  useEffect(() => {
    if (
      prevPageChanged !== pageChanged &&
      pageChanged &&
      !_.isNil(embeddedReportFilters)
    ) {
      setBookmark(null, null, true);
      setPageChanged(false);
    }
  }, [embeddedReportFilters, pageChanged, prevPageChanged, setBookmark]);

  const handleShareBookmark = useCallback(
    (bookmarkRes: BookmarkRes) => {
      if (showShareModal || !bookmarkRes) {
        return;
      }

      setSharedBookmark(bookmarkRes);
      setShowShareModal(true);
    },
    [showShareModal],
  );

  const handleCaptureUpdateBookmark = useCallback(
    (editMode: boolean = false, bookmarkRes?: BookmarkRes) => {
      if (showCaptureUpdateModal) {
        return;
      }

      setShowCaptureUpdateModal(true);

      const label = k2Intl?.formatMessage({
        localeKey: "reports.captureBookmark.name.title",
      });

      const requiredError = k2Intl?.formatMessage({
        localeKey: errorKeys.requiredValidationError,
        values: { fieldName: label },
      });

      if (editMode) {
        captureBookmarkForm?.setFieldsValue({
          ["name"]: bookmarkRes.name,
        });
      }

      showConfirm({
        titleKey: "reports.captureBookmark.title",
        content: (
          <Form
            form={captureBookmarkForm}
            scrollToFirstError
            layout={"vertical"}
            onFieldsChange={() => setDataChanged(true)}
            onFinishFailed={(errorInfo) => console.log("failed", errorInfo)}
          >
            <Row gutter={[12, 18]} className={"n3o-mb-0"}>
              <Col span={24}>
                <Form.Item
                  label={label}
                  {...formItemLayout}
                  name={"name"}
                  rules={[{ required: true, message: requiredError }]}
                >
                  <Input autoFocus />
                </Form.Item>
              </Col>
            </Row>
          </Form>
        ),
        onOk: async () => {
          return new Promise(async (resolve, reject) => {
            try {
              const values = captureBookmarkForm?.getFieldsValue();

              if (editMode) {
                const req: BookmarkReq = {
                  ...bookmarkRes,
                  name: values?.name,
                };

                captureBookmark(reportId, req, bookmarkRes.revisionId);
                captureBookmarkForm?.setFieldsValue({
                  ["name"]: undefined,
                });
              } else {
                const filters = await embeddedReport?.getFilters();

                const req: BookmarkReq = {
                  name: values?.name,
                  stateJson: JSON.stringify(filters),
                };

                captureBookmark(reportId, req);
                captureBookmarkForm?.resetFields();
              }

              setDataChanged(false);
              setShowCaptureUpdateModal(false);
              resolve(values);
            } catch (e) {
              setShowCaptureUpdateModal(false);
              reject(e);
            }
          }).catch((e) => {
            console.log("Oops errors!", e);
            setShowCaptureUpdateModal(false);
          });
        },
        onCancel: () => {
          setDataChanged(false);
          setShowCaptureUpdateModal(false);
        },
        okTextKey: editMode ? "common.update" : "common.save",
        okButtonProps: { loading: captureBookmarkLoading },
        k2Intl,
      });
    },
    [
      showCaptureUpdateModal,
      k2Intl,
      captureBookmarkForm,
      captureBookmarkLoading,
      embeddedReport,
      captureBookmark,
      reportId,
    ],
  );

  const handleCaptureBookmark = useCallback(() => {
    handleCaptureUpdateBookmark();
  }, [handleCaptureUpdateBookmark]);

  const handleUpdateBookmark = useCallback(
    (bookmarkRes: BookmarkRes) => {
      handleCaptureUpdateBookmark(true, bookmarkRes);
    },
    [handleCaptureUpdateBookmark],
  );

  const handleDeleteBookmark = useCallback(
    (bMarkId: string, bookmarkRevisionId: string, bookmarkName: string) => {
      if (showDeleteModal) {
        return;
      }

      setShowDeleteModal(true);

      showError({
        titleKey: "reports.bookmarks.delete.title",
        errorKey: "reports.bookmarks.delete.description",
        errorKeyValues: { bookmark: bookmarkName },
        maskClosable: false,
        onOk: async () => {
          return new Promise(async (resolve, reject) => {
            try {
              deleteBookmark(bookmarkRevisionId);

              if (bMarkId === bookmarkId) {
                setApplyingBookmark(true);

                embeddedReport
                  .setFilters(embeddedReportFilters)
                  .then(() => {
                    setApplyingBookmark(false);
                    setBookmarkId(null);
                  })
                  .catch((errors) => {
                    console.error(errors);
                  });
              }

              setShowDeleteModal(false);
              resolve(true);
            } catch (e) {
              setShowDeleteModal(false);
              reject(e);
            }
          }).catch((e) => {
            console.log("Oops errors!", e);
            setShowDeleteModal(false);
          });
        },
        okTextKey: "common.delete",
        okButtonProps: { loading: deleteBookmarkLoading, danger: true },
        k2Intl,
      });
    },
    [
      bookmarkId,
      deleteBookmark,
      deleteBookmarkLoading,
      embeddedReport,
      embeddedReportFilters,
      k2Intl,
      showDeleteModal,
    ],
  );

  const onShareBookmark = useCallback(
    async (form, access: AccessReq, onDone: () => void) => {
      const resp: IApiResponse<void> = await K2RestService.toResponse(
        _reportsClient.shareBookmark(sharedBookmark.revisionId, access),
      );

      if (resp.error) {
        if (resp.error.status === K2StatusCodes.preconditionFailed) {
          // Handle field-level validation errors and set them on the form
          UIUtils.handleValidationErrors(resp.error, form);

          const nonFieldRelatedErrors = UIUtils.getOverallValidationErrors(
            resp.error,
            "error",
          );

          if (nonFieldRelatedErrors.length) {
            showError({
              titleKey: "common.nonDismissableFormErrorTitle",
              error: nonFieldRelatedErrors?.map?.((e) => <p>{e.error}</p>),
              okTextKey: "common.ok",
              k2Intl,
            });
          }
        } else {
          UIUtils.handleServerError(k2Intl, resp?.error);
        }
      } else {
        showNotification({
          type: "success",
          k2Intl,
          titleKey: "common.success.title",
          message: UIUtils.messageToHtml(
            k2Intl,
            "reports.captureBookmark.shared.success",
            { bookmark: sharedBookmark.name },
          ),
        });
        setShowShareModal(false);
        fetchAllBookmarks({ reportId: reportId });
      }

      onDone();
    },
    [sharedBookmark, fetchAllBookmarks, k2Intl, reportId],
  );

  const onUnshareBookmark = useCallback(
    async (form, onDone: () => void) => {
      const resp: IApiResponse<void> = await K2RestService.toResponse(
        _reportsClient.unshareBookmark(sharedBookmark.revisionId),
      );

      if (resp.error) {
        if (resp.error.status === K2StatusCodes.preconditionFailed) {
          // Handle field-level validation errors and set them on the form
          UIUtils.handleValidationErrors(resp.error, form);

          const nonFieldRelatedErrors = UIUtils.getOverallValidationErrors(
            resp.error,
            "error",
          );

          if (nonFieldRelatedErrors.length) {
            showError({
              titleKey: "common.nonDismissableFormErrorTitle",
              error: nonFieldRelatedErrors?.map?.((e) => <p>{e.error}</p>),
              okTextKey: "common.ok",
              k2Intl,
            });
          }
        } else {
          UIUtils.handleServerError(k2Intl, resp?.error);
        }
      } else {
        showNotification({
          type: "success",
          k2Intl,
          titleKey: "common.success.title",
          message: UIUtils.messageToHtml(
            k2Intl,
            "reports.captureBookmark.shared.success",
            { bookmark: sharedBookmark.name },
          ),
        });
        setShowShareModal(false);
        fetchAllBookmarks({ reportId: reportId });
      }

      onDone();
    },
    [sharedBookmark, fetchAllBookmarks, k2Intl, reportId],
  );

  const width: number = useWindowWidth();
  const isMobileScreen = width <= MOBILE_SIZE;

  const pinToDashboardText = k2Intl?.formatMessage({
    localeKey: "reports.pinToDashboard",
  });

  return (
    <PageWithBreadcrumbsWrapper width={width}>
      <FormsWrapper width={width} id={"form-wrapper"}>
        {loading ? (
          <K2Card shadow>
            <Skeleton active />
          </K2Card>
        ) : serverError?.status === K2StatusCodes.internalServerError ? (
          <K2Card shadow>
            <Error500 />
          </K2Card>
        ) : serverError?.status === K2StatusCodes.notFound ? (
          <K2Card shadow>
            <Error404
              entity={k2Intl.formatMessage({ localeKey: "common.report" })}
            />
          </K2Card>
        ) : (
          <>
            <K2RouteHeading
              heading={
                <RouteHeadingWrapper>
                  <K2Link to={analytics}>
                    <ArrowLeftOutlined style={{ marginRight: "4px" }} />
                    <K2Message localeKey="reports.backToAllReports" />
                  </K2Link>
                  {embedReportRes?.name}
                </RouteHeadingWrapper>
              }
              className={"n3o-mb-3"}
            >
              <K2Button
                localeKey="analytics.manage.reportingPeriod"
                onClick={() => setShowManageReporting(true)}
                type="primary"
              />
            </K2RouteHeading>
            <DirtyDataGuard dirty={dataChanged}>
              <Layout>
                {reportLoaded && (
                  <SiderWrapper
                    trigger={null}
                    theme={"light"}
                    collapsible
                    collapsed={bookmarksCollapsed}
                  >
                    <BookmarkCollapseWrapper
                      className={"bookmark_collapse_wrapper"}
                      onClick={() => setBookmarksCollapsed(!bookmarksCollapsed)}
                    >
                      {bookmarksCollapsed ? (
                        <RightOutlined />
                      ) : (
                        <LeftOutlined />
                      )}
                      <span className={"bookmark_text_with_icon"}>
                        <N3oIcon
                          icontype="n3o-bookmark"
                          width={20}
                          height={20}
                          fill={theme.grey_7}
                          className={bookmarksCollapsed ? "" : "n3o-mr-2"}
                        />
                        <K2Message
                          localeKey={"common.bookmarks"}
                          spanClassName={"bookmark_text"}
                        />
                      </span>
                    </BookmarkCollapseWrapper>
                    {!bookmarksCollapsed && (
                      <>
                        {showShareModal && (
                          <EditPermissionsModal
                            titleKey={"reports.captureBookmark.shared.title"}
                            shared={sharedBookmark.isShared}
                            sharedAccess={sharedBookmark.sharedAccess}
                            onShare={onShareBookmark}
                            onUnshare={onUnshareBookmark}
                            onCancel={() => setShowShareModal(false)}
                          />
                        )}
                        <Spin
                          spinning={captureBookmarkLoading || bookmarksLoading}
                        >
                          <BookmarksWrapper>
                            {!_.isNil(allBookmarks?.items) &&
                            !_.isEmpty(allBookmarks?.items) &&
                            allBookmarks?.items?.length > 0 ? (
                              <BookmarksList
                                k2Intl={k2Intl}
                                bookmarkId={bookmarkId}
                                setBookmark={setBookmark}
                                bookmarks={allBookmarks?.items}
                                handleCaptureBookmark={handleCaptureBookmark}
                                handleUpdateBookmark={handleUpdateBookmark}
                                handleShareBookmark={handleShareBookmark}
                                handleDeleteBookmark={handleDeleteBookmark}
                              />
                            ) : (
                              <EmptyBookmarks
                                k2Intl={k2Intl}
                                handleCaptureBookmark={handleCaptureBookmark}
                              />
                            )}
                          </BookmarksWrapper>
                        </Spin>
                      </>
                    )}
                  </SiderWrapper>
                )}

                <Layout>
                  <Spin spinning={applyingBookmark || pinningVisual}>
                    <PowerBiEmbedded
                      key={reportId}
                      embedType={"report"}
                      mobile={isMobileScreen}
                      tokenType={TokenType.Embed}
                      accessToken={`${embedReportRes?.config?.accessToken}`}
                      embedUrl={`${embedReportRes?.config?.embedUrl}`}
                      id={`${embedReportRes?.config?.id}`}
                      filterPaneEnabled={true}
                      navContentPaneEnabled={true}
                      visualName={`${embedReportRes?.name}`}
                      style={{ height: !isMobileScreen ? "100vh" : 250 }}
                      onEmbedded={setEmbeddedReport}
                      extensions={[
                        {
                          command: {
                            name: pinToDashboardCommandName,
                            title: pinToDashboardText,
                            icon:
                              "data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABgAAAAYCAYAAADgdz34AAAACXBIWXMAAAsTAAALEwEAmpwYAAAAAXNSR0IArs4c6QAAAARnQU1BAACxjwv8YQUAAAFvSURBVHgBzZXNUcMwEIVXIgefQHYmM3a4hA7SAaGDdEA6wB1gKgAqIFQAHYQODBU4t+TkmBkOOUW8HQcMxv+OZ3gz/lvJ35N3JZmoYx1RCyk1GBvGsbHdfkR5fSQ1lFL2RMqeL6UIcH95cINEegmTeZ5JjxpKSnkOOO125Empz/YmFEXrR2oryxpeW5ajLcv2kpjtcawoXY3hBzMpgrOUcq5M0/Fjk8GY6qgMnrTHB75ixPFe9kjsEYrmEokTftZaK5ynOG7CcO1lwdHmcTuK/sIxFHvJV5EzGh9QEkK/JlHxFoaruyJ4lnmmkMcFXnwo61eWtlxximASFJk0hlcxAdhtBf8Buo1Bv00AnsP8uQpD5sO5eOTGT3qWNsEE2FBTg2RmfOses2pSpfClBn/hPP1WLgwu2ASpecL4sdGJd6qr9GpMF3FfeN807cXXSq2sft+eFcFbC9CgMzj//hhsmsMNdsQpdSGlTuttr/9Fn76GlLcFRSxuAAAAAElFTkSuQmCC",
                            extend: {
                              visualContextMenu: {
                                title: pinToDashboardText,
                              },
                              visualOptionsMenu: {
                                title: pinToDashboardText,
                              },
                            },
                          },
                        },
                      ]}
                    />
                  </Spin>
                </Layout>
              </Layout>
            </DirtyDataGuard>
            <ReportingPeriodDrawer
              showManageReporting={showManageReporting}
              setShowManageReporting={setShowManageReporting}
              refetchReportingPeriod={() => {
                fetchEmbeddedReport(reportId);
              }}
            />
          </>
        )}
      </FormsWrapper>
    </PageWithBreadcrumbsWrapper>
  );
};

export default injectK2Intl(
  connect(
    AnalyticsSelectors.getPinnedReportsSelector(),
    AnalyticsSelectors.getPinnedReportsDispatchers(),
  )(PinVisuals),
);
