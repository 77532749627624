import React, { FC } from "react";
import styled from "styled-components";
import { RequireAuth } from "containers/app/views";
import { asyncComponent } from "components/utils";
import { Col, Row } from "antd";
import { Route, Switch } from "react-router-dom";
import { K2Routes } from "appRedux/models/routes/K2Routes";
import K2Breadcrumb from "components/breadcrumb";
import _404Error from "components/errors/404";

const Wrapper = styled.div`
  & .async-component {
    margin-top: 25px;
  }
`;

const CommunicationsDashboardWithAuth = RequireAuth(
  asyncComponent(
    () =>
      import(/* webpackChunkName: "CommunicationsDashboard" */ "./dashboard"),
  ),
  "communications",
);

const EmailAdminWithAuth = RequireAuth(
  asyncComponent(
    () => import(/* webpackChunkName: "EmailsAdmin" */ "./emailsAdmin"),
  ),
  "emailsAdmin",
);

const PrintingWithAuth = RequireAuth(
  asyncComponent(() => import(/* webpackChunkName: "Printing" */ "./printing")),
  "printing",
);

const OutboxesWithAuth = RequireAuth(
  asyncComponent(
    () => import(/* webpackChunkName: "ViewOutboxes" */ "./outboxes"),
  ),
  "viewOutboxes",
);

const CallCenterSubRouter = RequireAuth(
  asyncComponent(
    () => import(/* webpackChunkName: "CallCenter" */ "../callCenter"),
  ),
  "callCenter",
);

const SendFromOutboxesWithAuth = RequireAuth(
  asyncComponent(
    () => import(/* webpackChunkName: "ViewOutboxes" */ "./sendFromOutboxes"),
  ),
  "sendFromOutboxes",
);

const CommunicationsRoutes: FC = () => {
  return (
    <>
      <K2Breadcrumb />
      <Wrapper>
        <Row gutter={18} style={{ display: "block" }}>
          <Col>
            <Switch>
              <Route
                exact
                path={K2Routes.main.communications}
                component={CommunicationsDashboardWithAuth}
              />
              <Route
                exact
                path={K2Routes.emailsAdmin}
                component={EmailAdminWithAuth}
              />
              <Route path={K2Routes.printing} component={PrintingWithAuth} />
              <Route
                exact
                path={K2Routes.viewOutboxes}
                component={OutboxesWithAuth}
              />
              <Route
                exact
                path={K2Routes.sendFromOutboxes}
                component={SendFromOutboxesWithAuth}
              />
              <Route
                path={K2Routes.callCenter}
                component={CallCenterSubRouter}
              />
              <Route path={"*"} component={_404Error} />
            </Switch>
          </Col>
        </Row>
      </Wrapper>
    </>
  );
};

export default CommunicationsRoutes;
