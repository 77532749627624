import React, { useState } from "react";

import { DownOutlined, UpOutlined } from "@ant-design/icons";
import { Dropdown, Menu } from "antd";
import { DropdownButtonProps } from "antd/lib/dropdown";
import styled from "styled-components";

import K2Permission from "../K2Permission";
import K2Button from "../k2Buttons/K2Button";

const DropdownButton = styled(Dropdown.Button)`
  button {
    border-color: ${({ theme }) => theme.primaryColor};
    color: ${({ theme, type }) =>
      type === "primary" ? theme.white_color : theme.primaryColor};
    &:hover,
    &:focus,
    &:active {
      color: ${({ theme, type }) =>
        type === "primary" ? theme.white_color : theme.button_hover_color};
    }
    &:first-child {
      border-top-right-radius: 0 !important;
      border-bottom-right-radius: 0 !important;
      margin-right: -1px !important;
    }

    &:first-child + button {
      border-top-left-radius: 0 !important;
      border-bottom-left-radius: 0 !important;
    }

    .anticon-down {
      font-size: 16px !important;
      padding-top: 5px;
    }
  }
  &&& {
    & > .ant-dropdown-trigger {
      margin-right: 0px !important;
    }
  }
`;

interface IK2DropdownButtonProps extends Omit<DropdownButtonProps, "overlay"> {
  permission?: string;
  loading?: boolean;
  children?: React.ReactNode;
  // this should be of K2MenuItem
  menuItems: React.ReactElement;
}
function K2DropdownButton(props: IK2DropdownButtonProps) {
  const { permission, menuItems } = props;
  const [visible, setVisible] = useState<boolean>(false);

  const renderOverley = (
    <Menu onClick={() => setVisible(false)}>{menuItems}</Menu>
  );

  const hasMenuItems = React.Children.count(menuItems.props.children) > 0;

  return (
    <K2Permission permission={permission}>
      {hasMenuItems ? (
        <DropdownButton
          onVisibleChange={setVisible}
          icon={visible ? <UpOutlined /> : <DownOutlined />}
          overlay={renderOverley}
          {...props}
        >
          {props.children}
        </DropdownButton>
      ) : (
        <K2Button {...props}>{props.children}</K2Button>
      )}
    </K2Permission>
  );
}

export default K2DropdownButton;
